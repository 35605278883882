// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-husky-src-pages-404-tsx": () => import("./../../../../gatsby-theme-husky/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-pages-404-tsx" */),
  "component---gatsby-theme-husky-src-templates-about-about-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/About/About.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-about-about-tsx" */),
  "component---gatsby-theme-husky-src-templates-contact-contact-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Contact/Contact.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-contact-contact-tsx" */),
  "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-languageselector-languageselector-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Languageselector/Languageselector.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-languageselector-languageselector-tsx" */),
  "component---gatsby-theme-husky-src-templates-legal-legal-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/Legal/Legal.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-legal-legal-tsx" */),
  "component---gatsby-theme-husky-src-templates-recipe-init-recipe-init-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/RecipeInit/RecipeInit.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-recipe-init-recipe-init-tsx" */),
  "component---gatsby-theme-husky-src-templates-recipe-page-recipe-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/RecipePage/RecipePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-recipe-page-recipe-page-tsx" */)
}

